import { useEffect, useState } from "react";

import PollarisApi from "../services/PollarisApi";

import ToastService from "../services/ToastService";

import { useForm } from 'react-hook-form';

import moment from "moment";
import { useNavigate } from "react-router-dom";

const FormCliente = ({ linkedProduto }) => {

    const apiPollaris = new PollarisApi();

    const toastService = new ToastService();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [cidades, setCidades] = useState([]);

    const [produto, setProduto] = useState(linkedProduto);

    const loadCidades = (uf) => {

        getCidades(uf).then(response => {

            setCidades(response);
        }
        ).catch(error => {

            const message = error?.message || error.title;

            toastService.error("Erro ao carregar cidades", message);
        });

    }

    const getCidades = (uf) => {

        return apiPollaris.get(`ibge/municipios/${uf}`)
    }


    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {

        toastService.info("Aguarde..", "Salvando...");

        if (!data.nascimento)
            data.nascimento = null;

        const payLoad = {
            cliente: data,
            produto: produto
        }

        setLoading(true);

        apiPollaris
            .post(`licenca`, payLoad)
            .then((response) => {

                toastService.success("Sucesso..", "Contratação concluída");

                navigate("/concluido/" + response.code);

                setLoading(false);
            })
            .catch((error) => {

                console.error(error);

                const message = error?.message || error.title;

                toastService.error("Erro ao salvar dados", message);

                setLoading(false);

            });

    }

    const consultaCnpjMatriz = (cnpj) => {
        
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (!cnpj) return;

    }

    const consultaCnpj = (cnpj) => {

        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (!cnpj) return;

        apiPollaris.get(`cnpj/${cnpj}`).then(response => {

            if (response) {

                setValue("nome", response.razao);
                setValue("cep", response.cep);
                setValue("endereco", response.endereco);
                setValue("numero", response.numero);
                setValue("fantasia", response.fantasia);
                setValue("email", response.email);
                setValue("telefone", response.telefone);
                setValue("contato", response.responsavel);
                setValue("bairro", response.bairro);

                if (response.estado) {

                    setValue("estado", response.estado);

                    if (response.municipio) {

                        getCidades(response.estado).then(cidades => {

                            setCidades(cidades);

                            setTimeout(() => {
                                const cidade = cidades.find(c => c.nome.toLowerCase() === response.municipio.toLowerCase());

                                console.log(cidade);

                                setValue("cidade", cidade.nome);
                            }, 500);

                        }).catch(error => {

                            const message = error?.message || error.title;

                            toastService.error("Erro ao carregar cidades", message);
                        });
                    }
                }

            }
        }
        ).catch(error => {

            const message = error?.message || error.title;

            toastService.error("Erro ao carregar dados", message);
        }

        );
    }


    return (
        produto ?
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" {...register('cnpj', { onBlur: (e) => consultaCnpj(e.target.value), required: true })} />
                            <label>CPF / CNPJ</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control " {...register('nome', { required: true })} />
                            <label>Nome / Razao Social</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control " {...register('fantasia', { required: false })} />
                            <label>Apelido / Nome Fantasia</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" {...register('ie', { required: false })} />
                            <label>RG / IE</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="date" className="form-control" {...register('nascimento', { required: false })} />
                            <label>Nascimento</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control " {...register('contato', { required: true })} />
                            <label>Responsável</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="email" className="form-control " {...register('email', { required: true })} />
                            <label>Email</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="phone" className="form-control " {...register('telefone', { required: true })} />
                            <label>Telefone</label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control " {...register('endereco', { required: true })} />
                            <label>Endereço</label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control " {...register('numero', { required: false })} />
                            <label>Número</label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-floating mb-3">
                            <select type="text" className="form-control " {...register('estado', { onChange: (e) => loadCidades(e.target.value), required: true })} >
                                <option value="">Selecione</option>
                                <option value="AC">AC</option>
                                <option value="AL">AL</option>
                                <option value="AP">AP</option>
                                <option value="AM">AM</option>
                                <option value="BA">BA</option>
                                <option value="CE">CE</option>
                                <option value="DF">DF</option>
                                <option value="ES">ES</option>
                                <option value="GO">GO</option>
                                <option value="MA">MA</option>
                                <option value="MT">MT</option>
                                <option value="MS">MS</option>
                                <option value="MG">MG</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PR">PR</option>
                                <option value="PE">PE</option>
                                <option value="PI">PI</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RS">RS</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="SC">SC</option>
                                <option value="SP">SP</option>
                                <option value="SE">SE</option>
                                <option value="TO">TO</option>
                            </select>
                            <label>Estado</label>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="form-floating mb-3">
                            <select type="text" className="form-control " {...register('cidade', { required: true })} >
                                <option value="">Selecione</option>
                                {
                                    cidades.map((cidade) => {
                                        return <option key={cidade.id} value={cidade.nome}>{cidade.nome}</option>
                                    })
                                }
                            </select>
                            <label>Cidade</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control " {...register('bairro', { required: true })} />
                            <label>Bairro</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control " {...register('cep', { required: true })} />
                            <label>CEP</label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" {...register('cnpjMatriz', { onBlur: (e) => consultaCnpjMatriz(e.target.value), required: false })} />
                            <label>CPF / CNPJ Matriz</label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            {
                                loading ?
                                    <button type="button" disabled className={`btn form-control col-12 btn-dark btn-lg bg-${produto.programa}`} >
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {" "} Finalizando...
                                    </button>
                                    :
                                    <button type="submit" className={`btn form-control col-12 btn-dark btn-lg bg-${produto.programa}`} >
                                        <i className="bi bi-check"></i> Finalizar
                                    </button>

                            }

                        </div>
                    </div>
                </div>
            </form >
            : null
    );
};

export default FormCliente;